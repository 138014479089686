<script setup>
import { computed, inject } from "vue";
import AddStepButton from "./AddStepButton.vue";
import RemoveBlockButton from "./RemoveBlockButton.vue";
import Steps from "../Step/Steps.vue";

const store = inject('store');
const {blockIndex} = defineProps(['blockIndex']);
const block = store.blocks[blockIndex];
const border = computed(() => store.activeBlockIndex === blockIndex ? '1px solid red' : null);
</script>

<template>
    <div class="journey-block" :style="{ border: border}">
        <b v-if="block.after === 0">Immediately</b>
        <b v-else>After {{ block.after }} days</b>
        <Steps :block-index="blockIndex"/>
        <AddStepButton :block-index="blockIndex"/>
        <RemoveBlockButton :block-index="blockIndex"/>
    </div>
</template>
