<script setup>
import { inject } from "vue";
import Step from "./Step.vue";

const store = inject('store');
const { blockIndex } = defineProps(['blockIndex']);
const block = store.blocks[blockIndex];
</script>

<template>
<!--    <VueDraggable v-model="steps" group="steps">-->
        <div v-for="(step, stepIndex) in block.steps" :key="step.id">
            <Step :block-index="blockIndex" :step-index="stepIndex"/>
        </div>
<!--    </VueDraggable>-->
</template>
