<script setup>
import { inject } from "vue";
import AddBlockButton from "./AddBlockButton.vue";
import Block from "./Block.vue";

const store = inject('store');

// const onEndDrag = async (event) => {
//     await nextTick();
//
//     if (event.newIndex === 0) {
//         event.data.after = 0;
//     } else {
//         event.data.after = blocks.value[event.newIndex - 1].after + 1;
//     }
// };
</script>

<template>
    <div class="journey-builder">
        <div class="journey-builder__inner">
            <!--    <VueDraggable v-model="blocks" @end="onEndDrag" class="journey-blocks">-->
            <div class="journey-blocks">
                <template v-for="(block, index) in store.blocks" :key="block.id">
                    <Block :block-index="index"/>
                </template>
            </div>
            <!--    </VueDraggable>-->
            <AddBlockButton/>
        </div>
    </div>
</template>
