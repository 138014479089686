<script setup>
import {computed, provide, reactive} from 'vue';
import axios from 'axios';
import {ulid} from 'ulid';
import Actions from "./Actions/Actions.vue";
import Blocks from "./Navigation/Block/Blocks.vue";
import MainArea from "./MainArea/MainArea.vue";

// TODO: Drag & Drop disabled
// TODO: Reset and isDirty still not working properly...
// TODO: Lint / codestyle check for Vue files
// TODO Validate props, see https://vuejs.org/guide/components/props.html#prop-validation
const props = defineProps({
    initialJourney: {
        type: Object,
        required: true,
    },
    saveUrl: {
        type: String,
        required: true,
    }
});

const store = reactive({
    // A list of blocks, each containing a list of steps.
    blocks: [...props.initialJourney.blocks],

    // Store the original initialJourney in a non-reactive variable.
    // This ensures we keep the original state regardless of what changes happen.
    originalJourney: JSON.parse(JSON.stringify(props.initialJourney)),

    // Whether anything in the journey has been modified.
    isDirty: computed(() => JSON.stringify(store.blocks) !== JSON.stringify(store.originalJourney.blocks)),

    // The active block (if any).
    activeBlockIndex: null,

    // The active step within the active block (if any).
    activeStepIndex: null,

    // The currently active step object.
    activeStep: computed(() => {
        if (store.activeBlockIndex === null || store.activeStepIndex === null) {
            return null;
        }

        return store.blocks[store.activeBlockIndex].steps[store.activeStepIndex];
    }),

    // The currently active step's type.
    activeStepType: computed(() => {
        if (store.activeStep === null) {
            return null;
        }

        return store.activeStep.type;
    }),

    addBlock: () => {
        store.blocks.push({
            id: ulid(),
            after: store.blocks.length > 0 ? store.blocks[store.blocks.length - 1].after + 1 : 0,
            steps: [],
        });
        store.activeBlockIndex = store.blocks.length - 1;
        store.activeStepIndex = null;
    },

    removeBlock: (blockIndex) => {
        store.activeBlockIndex = null;
        store.activeStepIndex = null;
        store.blocks.splice(blockIndex, 1);
    },

    addStep: (blockIndex, type) => {
        switch (type) {
            case 'NotifySuccessManagerStep':
                store.blocks[blockIndex].steps.push({
                    id: ulid(),
                    type: type,
                    message: '',
                });
                break;

            case 'SendEmailStep':
                store.blocks[blockIndex].steps.push({
                    id: ulid(),
                    type: type,
                    body: {
                        html: '',
                        json: {
                            type: 'doc',
                            content: [],
                        },
                    },
                });
                break;

            default:
                return;
        }

        store.activeBlockIndex = blockIndex;
        store.activeStepIndex = store.blocks[blockIndex].steps.length - 1;
    },

    // Edit a step in the main area.
    editStep: (blockIndex, stepIndex) => {
        store.activeBlockIndex = blockIndex;
        store.activeStepIndex = stepIndex;
    },

    // Remove a step from a block.
    removeStep: (blockIndex, stepIndex) => {
        store.blocks[blockIndex].steps.splice(stepIndex, 1);
        store.activeStepIndex = null;
    },

    // Reset function that restores the journey to its original (last saved) state.
    resetJourney: () => {
        store.activeBlockIndex = null;
        store.activeStepIndex = null;
        store.blocks = [...JSON.parse(JSON.stringify(store.originalJourney)).blocks];

        // nextTick(() => {
        //     isDirty.value = false;
        // });
    },

    // Save the journey to the database.
    saveJourney: () => {
        axios.post(props.saveUrl, { blocks: store.blocks})
            .then(() => {
                // isDirty.value = false;
                // originalJourney.value = JSON.parse(JSON.stringify(journey.value));
            })
            .catch(error => {
                alert(error);
            });
    },
});

provide('store', store);

// watch(store.value.journey, () => {
//     store.value.isDirty = true;
// }, {deep: true});
</script>

<template>
  <div class="journey">
    <Blocks/>
    <MainArea/>
    <Actions/>
  </div>
</template>
